import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"
import Alert from "@kiwicom/orbit-components/lib/icons/Alert"
import Bus from "@kiwicom/orbit-components/lib/icons/Bus"
import InformationCircle from "@kiwicom/orbit-components/lib/icons/InformationCircle"
import Pharmacy from "@kiwicom/orbit-components/lib/icons/Pharmacy"
import Security from "@kiwicom/orbit-components/lib/icons/Security"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Policies = () => (
  <DriveLayout title="Staff Policies">
    <Hero title="Staff Policies" />
    <Container>
      <Stack spacing="XLarge">
        <EmberCard icon={<Bus />} title="Hours & Pay">
          <EmberCardSection>
            <Stack direction="column">
              <TextLink href="pay/">Pay & Overtime Structure</TextLink>
              <TextLink href="payslips/">When and How You'll Be Paid</TextLink>
              <TextLink href="shift-allocation/">
                Shift Allocation & Preferences
              </TextLink>
              <TextLink href="relief-drivers/">
                Relief Driver Expectations
              </TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard
          icon={<Pharmacy />}
          title="Health, Wellbeing and Taking Time Off"
        >
          <EmberCardSection>
            <Stack direction="column">
              <Text>
                We want to create a happy, healthy work environment. This
                includes providing support for staff with health issues (whether
                mental or physical), caring commitments or those who have
                experienced a bereavement. The most important thing is to know
                that you can talk to us – either the Operations Team, your
                manager or Keith/Pierce – about any of these things and we will
                keep it treat it seriously.
              </Text>
              <Heading type="title5">Taking Time Off</Heading>
              <TextLink href="annual-leave/">Annual Leave</TextLink>
              <TextLink href="unplanned-leave/">
                Unplanned Leave For Illness, Family Emergencies or Jury Duty
              </TextLink>
              <TextLink href="parental-leave/">Parental Leave</TextLink>
              <Heading type="title5">Looking After Your Health</Heading>
              <TextLink href="mental-health/">
                Mental Health & Wellbeing Policy
              </TextLink>
              <TextLink href="cycle-to-work/">Cycle to Work Scheme</TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard icon={<Bus />} title="Our Expectations">
          <EmberCardSection>
            <Stack direction="column">
              <Heading type="title5">Driving Safely</Heading>
              <Text>
                As a driver, your number one priority is the safety of our
                passengers and other road users. Driving safely trumps all other
                considerations – hitting timings, being helpful to passengers,
                running services and so on – so any concerns we have related to
                this will always be treated as the top-priority. We have
                developed a specific policy related to use of mobile phones.
              </Text>
              <TextLink href="mobile-phones/">Mobile Phone Policy</TextLink>
              <Text>
                We may add further policies in due course. Note that in the
                absence of a specific policies we still expect you to follow all
                traffic laws, the highway code and rules related to driver's
                hours. Failure to do this may be seen as gross misconduct,
                depending on the severity of the offence. If you ever feel
                something we are asking you to do will compromise safety, let us
                know immediately.
              </Text>
              <Heading type="title5">
                Disciplinary and Grievance Process
              </Heading>
              <Text>
                Where possible, we prefer to deal with HR issues informally,
                using a common sense approach and clear communication. In most
                cases, these discussions will take place between the Line
                Manager and the Employee. In the event that an issue needs to be
                escalated, either by a Line Manager or by a Driver, the Head of
                Operations will be notified and a disciplinary process may be
                invoked.
              </Text>
              <TextLink href="disciplinary-and-grievance/">
                Policy and Process
              </TextLink>
              <Heading type="title5">Conduct at Work</Heading>
              <Text>
                As an Ember employee, we also expect you to be a helpful and
                conscientous when dealing with passengers, other members of the
                public and other staff. We'll let you know if you have fallen
                short on one of these points. Serious or repeated issues may be
                seen as misconduct. In extreme situations (such as abuse), the
                violation may immediately be considered to be gross misconduct
                which could lead to dismissal.
              </Text>
              <TextLink href="company-chat/">
                Company Internal Chat Guidelines
              </TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard icon={<Alert />} title="Health & Safety">
          <EmberCardSection>
            <Stack direction="column">
              <Text>
                Our health and safety duties, including fire safety, are the
                responsibility of Keith Bradbury. You can read more about
                Ember's responsibilities as an employer{" "}
                <TextLink
                  type="secondary"
                  href="/downloads/health-and-safety-made-simple.pdf"
                >
                  here
                </TextLink>
                . As an employee, you should familiarise yourself with the
                leaflet below, which sets out the key points of health and
                safety law, including your responsibilities.
              </Text>
              <TextLink external href="/downloads/health-and-safety-law.pdf">
                Health & Safety Law Leaflet
              </TextLink>
              <Text>
                You can also review our health and safety related policies and
                documents below:
              </Text>
              <TextLink
                href="https://goember.notion.site/Health-and-Safety-Policy-c1187181b8804075a94cad5cc705119f"
                external
              >
                Health & Safety Policy
              </TextLink>
              <TextLink href="risk-assessment/">Risk Assessment</TextLink>
              <TextLink href="certificate-of-insurance/">
                Certificate of Employers' Liability Insurance
              </TextLink>
              <Text>
                If you have an accident at work, you should report it to the
                operations team. They will be able to arrange for first aid. In
                more serious situations, they will also report and keep a record
                of the injury, incidents or work-related disease. This is
                required by law.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard
          icon={<InformationCircle />}
          title="Harassment and Discrimination"
        >
          <EmberCardSection>
            <Stack direction="column">
              <Text>
                We are committed to equality and diversity and will not tolerate
                discrimination. This includes full- and part-time employees,
                temporary employees, people working on contracts and customers.
                Our Equal Opportunities Policy sets out what this means in a bit
                more detail, including what to do if you feel that you have been
                a victim of harassment or discrimination.
              </Text>
              <TextLink href="/equal-opportunities-policy/">
                Equal Opportunity Policy
              </TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard icon={<Security />} title="Compliance">
          <EmberCardSection>
            <Stack direction="column">
              <Text>
                Our compliance managers are Pierce Glennie and Keith Bradbury.
                We hope it's obvious that we have a zero-tolerance position on
                bribery and corruption but you should familiarise yourself with
                our full policy below, to ensure you understand what this
                actually means. Let us know if you have any questions or
                concerns.
              </Text>
              <TextLink href="anti-corruption/">
                Anti-Bribery & Anti-Corruption Policy
              </TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Policies
